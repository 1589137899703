/** * 关于我们  */
<template>
  <v-card flat>
    <v-banner single-line class="banner">
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/h5About-banner.png"
        width="100%" contain
        :transition="false">
        <template v-slot:placeholder><img-placeholder></img-placeholder></template>
        <div class="width-per-100 height-per-100 d-flex align-center justify-end">
          <div class="mr-8">
            <v-card-title class="pa-0 color-FFF font-size-h5banner-title" style="text-shadow: 0px 2px 18px rgba(0, 52, 127, 0.5);">关于我们</v-card-title>
            <v-card-text class="pa-0 mt-2 color-FFF font-size-h5banner-tip" style="text-shadow: 0px 2px 18px rgba(0, 52, 127, 0.5);text-align: right">ABOUT US</v-card-text>
          </div>
        </div>
      </v-img>
    </v-banner>
    <div class="px-4 py-7">
      <v-card flat class="d-flex">
        <div class="pt-7 pl-5 font-size-14 line-height-24">
          <v-img contain height="188" max-width="168" src="https://h5.ophyer.cn/official_website/other/about-img-1.png" style="float: left;margin-right: 10px;"/>
          <p>飞天云动集团内公司掌中飞天成立于2008年，创立之初主营业务为游戏研发发行，2015年正式开展 AR/VR企业服务，目前已发展成为中国元宇宙场景应用层（AR/VR内容及服务市场）的优质供应商。
            公司凭借自研的AR/VR引擎布局元宇宙生态，为娱乐、互联网、电商、房产、文旅、教育、金融、房地产、汽车、直播、技术等各行业企业实现数字化升级及业务扩充赋能。
            公司的使命为凭借AR/VR技术打破现实世界与虛拟世界之间的次元壁垒，推动元宇宙发展和成长。目前，公司已与百度、京东、阿里、腾讯等互联网巨头在元宇宙方向的布局上建立了深入合作。</p>
        </div>
      </v-card>
    </div>
    <v-card flat class="mt-3">
      <v-card-title class="justify-center font-size-20 pa-0 line-height-28">发展历程</v-card-title>
      <div class="width-per-100">
        <div class="mt-7">
          <div class="warp-stepper">
            <div id="stepper-tab" class="width-per-100 px-2" style="overflow-x: auto;" ref="stepper">
              <ul class="stepper-tab">
                <li
                    v-for="(v, k) in data.warp2"
                    :key="k"
                    :class="{ checked: v.id == checked }"
                    @click="hanldChecked($event,v.id)"
                >
                  <span>{{ v.year }}</span>
                </li>
              </ul>
            </div>
            <ul class="stepper-item">
              <span>{{ data.warp2[checked-1].year }}</span>
              <li v-for="(v, k) in data.warp2[checked-1].text" :key="k">
                {{ v }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </v-card>
    <v-card flat class="width-per-100">
      <v-card-title class="justify-center font-size-20 pa-0 mt-7 line-height-28">公司荣誉</v-card-title>
      <div class="px-4 mt-4 width-per-100 position-r">
        <div class="tab-btn-prev" @click.stop="handleTabBtn('prev')"></div>
        <div class="tab-btn-next" @click.stop="handleTabBtn('next')"></div>
        <div class="d-flex width-per-100 about-jo-dom" ref="aboutJo">
          <div class="about-jb-img1">
            <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-1.jpg"></v-img>
          </div>
          <div class="ml-3">
            <div class="about-jb-img2">
              <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-5.jpg"></v-img>
            </div>
            <div class="about-jb-img2" style="margin-top: 10px">
              <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-6.jpg"></v-img>
            </div>
          </div>
          <div class="ml-3">
            <div class="about-jb-img2">
              <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-7.jpg"></v-img>
            </div>
            <div class="about-jb-img2" style="margin-top: 10px">
              <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-8.jpg"></v-img>
            </div>
          </div>
          <div class="about-jb-img1 ml-3">
            <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-2.jpg"></v-img>
          </div>
          <div class="about-jb-img1 ml-3">
            <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-3.jpg"></v-img>
          </div>
          <div class="ml-3">
            <div class="about-jb-img2">
              <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-9.jpg"></v-img>
            </div>
            <div class="about-jb-img2" style="margin-top: 10px">
              <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-10.jpg"></v-img>
            </div>
          </div>
          <div class="about-jb-img1 ml-3">
            <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-4.jpg"></v-img>
          </div>
          <div class="ml-3">
            <div class="about-jb-img2">
              <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-11.jpg"></v-img>
            </div>
            <div class="about-jb-img2" style="margin-top: 10px">
              <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-12.jpg"></v-img>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <v-card flat class="width-per-100 mt-6 px-4 pt-6 pb-12" color="#F5F8FC">
      <v-card-title class="justify-center font-size-20 pa-0  line-height-28">联系我们</v-card-title>
      <v-card flat
              class="mt-4 pt-5 pl-4 pb-4"
              style="border-radius: 10px;"
              img="https://h5.ophyer.cn/official_website/other/h5About01.png">
        <v-img src="@/assets/login/logo_bai.png" height="37" max-width="120" contain></v-img>
        <v-card-title class="mt-3 pa-0 font-size-18 color-FFF line-height-25">北京飞天云动科技有限公司</v-card-title>
        <v-card-text class="mt-1 pa-0 font-size-13 color-FFF line-height-18">北京市朝阳区广渠路3号竞园艺术中心商8</v-card-text>
        <v-card-title class="mt-5 pa-0 font-size-24 color-FFF line-height-33">4006189949</v-card-title>
        <v-card-text class="mt-2 pa-0 font-size-13 line-height-18 color-FFF">商务/广告联系：business@ophyer.com</v-card-text>
        <v-card-text class="mt-1 pa-0 font-size-13 line-height-18 color-FFF">求职联系：hr@ophyer.com</v-card-text>
      </v-card>
      <v-card flat class="width-per-100 mt-3" style="z-index: 9;">
        <el-amap class="el-amap" :amap-manager="amapManager" :zoom="zoom" :center="center" :vid="'amap-vue'">
          <el-amap-marker :position="center"></el-amap-marker>
        </el-amap>
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
import textData from '@/static/textData.js'
import { AMapManager } from "vue-amap";
export default {
  name: 'About',
  components: {
  },
  data() {
    return {
      amapManager: null,
      center: [116.51097, 39.89401],
      zoom: 17,
      data: textData.About,
      checked: 10,
    }
  },
  computed: {
    //  
  },
  created() {
    // this.swiper.slideTo(3, 1000, false)
  },
  mounted() {
    this.$nextTick(()=>{
      this.$refs.stepper.scrollTo({
        left: 300,
        behavior: "smooth"
      });
      this.amapManager = new AMapManager();
    })
  },
  methods: {
    handleScroll (e,refName) {
      //获取当前可视区宽度
      let centerWidth = document.body.clientWidth/2;
      //计算滚动距离，设置scorllLeft
      this.$refs[refName].scrollLeft = e.target.offsetParent.offsetLeft - centerWidth;
    },
    hanldChecked(e,id) {
      this.checked = id
      this.handleScroll(e,"stepper");
    },
    handleTabBtn: function (type){
      //获取当前可视区宽度
      let centerWidth = document.body.clientWidth/2 - 6;
      let scrollLeft = this.$refs.aboutJo.scrollLeft;
      if(type === "prev"){
        scrollLeft -= centerWidth;
      }else if(type === "next"){
        scrollLeft += centerWidth;
      }
      this.$refs.aboutJo.scrollTo({
        left: scrollLeft,
        behavior: "smooth"
      });
    }
  }

}
</script>

<style lang="scss" scoped>
.about-jo-dom{
  width: calc(100vw - 32px);
  box-shadow: 0px 0px 18px 0px rgba(0, 92, 193, 0.16);
  overflow-x: auto;
  .about-jb-img1{
    width: calc(50vw - 22px);
    height: 258px;
    box-shadow: 0px 0px 18px 0px rgba(0, 92, 193, 0.16);
    border-radius: 5px;
  }
  .about-jb-img2{
    width: calc(50vw - 22px);
    height: 124px;
    box-shadow: 0px 0px 18px 0px rgba(0, 92, 193, 0.16);
    border-radius: 5px;
  }
}
// @keyframes  {
  
// }
.warp-stepper {
  padding: 0 16px;
  .stepper-tab {
    min-width: 560px;
    // width: 100%;
    padding-left: 0 !important;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    position: relative;
    z-index: 3;
    li {
      width: 14px;
      height: 14px;
      border: 2px solid #C9C9C9;
      border-radius: 50%;
      margin-right: 40px;
      position: relative;
      cursor: pointer;
      transition: background-color .5s linear 0s;
      span {
        position: absolute;
        top: -51px;
        left: -18px;
        width: 43px;
        height: 20px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        font-size: 14px;
        font-weight: 100 !important;
        font-family: unset;
        color: #333333;
        line-height: 20px;
        text-align: center;
        transition: background-color .5s linear 0s;
        &::before {
          content: "";
          width: 2px;
          height: 30px;
          background: linear-gradient(#cccccc, #ffffff);
          position: absolute;
          bottom: -30px;
          left: 50%;
        }
      }
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 12px;
        width: 40px;
        height: 1.5px;
        background: #cccccc;
        cursor: default;
      }
      &:nth-child(1)::before {
        display: none;
      }
      &:nth-child(even)  {
        font-size: 60px;
        span {
          position: absolute;
          top: 41px;
          &::before {
            content: "";
            width: 2px;
            height: 30px;
            background: linear-gradient( #ffffff, #cccccc);
            position: absolute;
            top: -30px;
            left: 50%;

          }
        }
      }
    }
    .checked {
      border: 2px solid #3579ff !important;
      background: #3579ff;
      span {
        background: #3579FF;
        color: #fff;
        &::before {
          background: #3579ff !important;
        }
      }
      &::after {
        content: "";
        width: 26px;
        height: 26px;
        background: rgba(38, 136, 220,0.2);;
        border-radius: 50%;
        position: absolute;
        bottom: -50%;
        left: 50%;
        transition: unset;
        transform: translate(-50%, 10%);
      }
    }
  }

  .stepper-item {
    min-height: 192px;
    border-radius: 10px;
    padding: 28px 30px 18px 30px;
    background: skyblue;
    background: linear-gradient(#fefefe, #f4f5f7);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    position: relative;
    z-index: 2;
    li {
      width: 100%;
      font-size: 13px;
      line-height: 27px;
      font-weight: 400;
      color: #333333;
      text-indent: -10px;
      position: relative;
    }
    span {
      position: absolute;
      line-height: 105px;
      font-size: 105px;
      font-weight: 400;
      letter-spacing: 2px;
      mask-image:-webkit-gradient(linear, 0 0, 0 bottom, from(#f3f2f2), to(rgba(0, 0, 255, 0)));
      color: #f3f2f2;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
      user-select: none;
    }
  }
}
.el-amap {
  width: 100%;
  height: 280px; 
  border-radius: 30px !important;
  background: #fff !important;
  .amap-maps {
    border-radius: 30px !important;
    background: #fff !important;
  }
}
.banner {
  ::v-deep .v-banner__wrapper, ::v-deep .v-banner__content, ::v-deep .v-banner__text {
    padding: 0 !important;
  }
}
</style>